import React, { ReactElement } from 'react'

import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
// import StarKitchenTriangle from '../components/star-kitchen-triangle'
import StarKitchenTitle from '../components/star-kitchen-title'
import Container from '../components/container'
// import { actions } from '../components/updates/punches'
import { UpdateActions, ActionButton, UpdateTitle, UpdateImage } from '../components/update'
import styled from 'styled-components'
import colors from '../lib/colors'

const Separator = styled.div`
  background: ${colors.offWhite};
  display: block;
  height: 2px;
  margin: 20px auto;
  max-width: 300px;
  width: 100%;
`

function LandingPage(): ReactElement {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/darkness-and-demons-album-cover.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Layout showNav={false} canonical="/landing">
      <Container style={{ paddingTop: 10 }}>
        <Link to={`/`}>
          <StarKitchenTitle />
        </Link>

        <ActionButton block big>
          <a
            href="https://www.beardfest.net/"
            rel="noreferrer noopener"
            style={{ display: "block", textAlign: "center" }}
          >
            <div>
              Saturday, June 15
            </div>
            <div>
              Beardfest, Hammonton, NJ
            </div>
            <div style={{ fontSize: '12px'}}>
              with special guest Jeremy Schon
            </div>
          </a>
        </ActionButton>

        <Separator />

        <UpdateTitle>Latest Release: Darkness &amp; Demons</UpdateTitle>
        <UpdateActions style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          <ActionButton block big first>
            <a
              href="https://open.spotify.com/album/73h8XPQDjXWWlQsywzNuxD"
              rel="noreferrer noopener"
              target="_blank"
              style={{ display: "block", textAlign: "center" }}
            >
              Listen on Spotify
            </a>
          </ActionButton>
          <ActionButton block big>
            <a
              href="https://music.apple.com/us/album/darkness-demons-single/1696865427"
              rel="noreferrer noopener"
              target="_blank"
              style={{ display: "block", textAlign: "center" }}
            >
              Listen on Apple Music
            </a>
          </ActionButton>
        </UpdateActions>
        <UpdateImage>
          <a href="https://color-red.com/products/darkness-demons">
            <Img
              fluid={data.file.childImageSharp.fluid}
              fadeIn={false}
              alt=""
              critical={false}
              loading="eager"
              style={{ maxWidth: '400px', margin: '16px auto' }}
            />
          </a>
        </UpdateImage>

        {/* <Separator /> */}

        {/* <UpdateTitle>Spring Tour Merch</UpdateTitle>
        <UpdateActions style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          <ActionButton block big first>
            <a
              href="https://py.pl/HmWMI"
              rel="noreferrer noopener"
              style={{ display: "block", textAlign: "center", fontSize: "14px" }}
            >
              <div>
                The More You Know Star Kitchen T-Shirt
              </div>
            </a>
          </ActionButton>
          <ActionButton block big>
            <a
              href="https://py.pl/1ObCGn"
              rel="noreferrer noopener"
              style={{ display: "block", textAlign: "center" }}
            >
              <div>
                April 2023 Tour Poster
              </div>
            </a>
          </ActionButton> */}
          {/* <ActionButton block big>
            <a
              href="https://www.ticketweb.com/event/star-kitchen-brooklyn-bowl-tickets/12994645?pl=bbowl"
              rel="noreferrer noopener"
              style={{ display: "block", textAlign: "center" }}
            >
              <div>
                Saturday, April 22
              </div>
              <div>
                Brooklyn Bowl, New York, NY
              </div>
              <div style={{ fontSize: '12px'}}>
                with special guests to be announced
              </div>
            </a>
          </ActionButton>
          <ActionButton block big>
            <a
              href="https://link.dice.fm/v4bf26a02de4"
              rel="noreferrer noopener"
              style={{ display: "block", textAlign: "center" }}
            >
              <div>
                Sunday, April 23
              </div>
              <div>
                8x10, Baltimore, MD
              </div>
            </a>
          </ActionButton>
        </UpdateActions> */}

        <Separator />

        <UpdateTitle>Streaming Now</UpdateTitle>
        <UpdateActions style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          <ActionButton block big first>
            <a
              href="https://open.spotify.com/artist/7pHa2zdgnRHA8Dnk9cPB5f"
              rel="noreferrer noopener"
              target="_blank"
              style={{ display: "block", textAlign: "center" }}
            >
              Spotify
            </a>
          </ActionButton>
          <ActionButton block big>
            <a
              href="https://music.apple.com/artist/star-kitchen/1518920442"
              rel="noreferrer noopener"
              target="_blank"
              style={{ display: "block", textAlign: "center" }}
            >
              Apple Music
            </a>
          </ActionButton>
          <ActionButton block big>
            <a
              href="https://2nu.gs/StarKitchen"
              rel="noreferrer noopener"
              target="_blank"
              style={{ display: "block", textAlign: "center" }}
            >
              Full Shows on Nugs.net
            </a>
          </ActionButton>
        </UpdateActions>

        <UpdateActions style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          <div style={{ paddingTop: '2em' }}></div>
          <ActionButton block big>
            <Link to={`/`}>Enter the Star Kitchen website</Link>
          </ActionButton>
        </UpdateActions>
      </Container>
    </Layout>
  )
}

export default LandingPage
